const moment = require('moment');
const { DATETIME_FORMAT } = require('@/utils/constants');
const DATE_FORMAT = 'DD/MM/YYYY';

const DP_CONFIG = {
  date: {
    format: 'DD/MM/YYYY',
    useCurrent: false,
    showClear: true,
    showClose: true,
    minDate: false,
    maxDate: false,
    locale: 'vi',
    sideBySide: true,
    allowInputToggle: true,
  },
  time: {
    format: 'LT',
    useCurrent: true,
    sideBySide: true,
    locale: 'vi',
    allowInputToggle: true,
  },
};

const DP_CONFIG_DATETIME = {
  date: {
    format: DATETIME_FORMAT,
    useCurrent: false,
    showClear: true,
    showClose: true,
    minDate: false,
    maxDate: false,
    sideBySide: true,
    locale: 'vi',
  },
};

const formatDate = (date, format) => {
  return moment(date).format(format || 'YYYY-MM-DD');
};

const getSubtractDate = (subtract = 0, format = DATE_FORMAT) => {
  return moment()
    .subtract(subtract, 'days')
    .format(format);
};

const getAddDate = (add = 0, format = DATE_FORMAT) => {
  return moment()
    .add(add, 'days')
    .format(format);
};

const getRemainingDays = (startDate, endDate, format = DATETIME_FORMAT) => {
  return moment(endDate, format).diff(moment(startDate, format), 'days');
};
module.exports = {
  formatDate,
  getSubtractDate,
  getAddDate,
  getRemainingDays,
  DP_CONFIG,
  DP_CONFIG_DATETIME,
};
